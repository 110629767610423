@keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-webkit-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-moz-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-ms-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@-o-keyframes vex-flyin {
  /* line 25, ../sass/_keyframes.sass */
  0% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }

  /* line 28, ../sass/_keyframes.sass */
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-webkit-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-moz-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-ms-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@-o-keyframes vex-flyout {
  /* line 34, ../sass/_keyframes.sass */
  0% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

  /* line 37, ../sass/_keyframes.sass */
  100% {
    opacity: 0;
    transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
  }
}

@keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-webkit-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-moz-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-ms-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

@-o-keyframes vex-pulse {
  /* line 136, ../sass/_keyframes.sass */
  0% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }

  /* line 138, ../sass/_keyframes.sass */
  70% {
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
  }

  /* line 140, ../sass/_keyframes.sass */
  100% {
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    -moz-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent;
  }
}

/* line 13, ../sass/vex-theme-os.sass */
.vex.vex-theme-os {
  padding-top: 160px;
  padding-bottom: 160px;
}
/* line 17, ../sass/vex-theme-os.sass */
.vex.vex-theme-os.vex-closing .vex-content {
  animation: vex-flyout 0.5s;
  -webkit-animation: vex-flyout 0.5s;
  -moz-animation: vex-flyout 0.5s;
  -ms-animation: vex-flyout 0.5s;
  -o-animation: vex-flyout 0.5s;
  -webkit-backface-visibility: hidden;
}
/* line 20, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-content {
  animation: vex-flyin 0.5s;
  -webkit-animation: vex-flyin 0.5s;
  -moz-animation: vex-flyin 0.5s;
  -ms-animation: vex-flyin 0.5s;
  -o-animation: vex-flyin 0.5s;
  -webkit-backface-visibility: hidden;
}
/* line 23, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-content {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px #a6a6a6, 0 0 0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0 1px #a6a6a6, 0 0 0 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px #a6a6a6, 0 0 0 1px rgba(0, 0, 0, 0.08);
  font-family: "Helvetica Neue", sans-serif;
  border-top: 20px solid #bbbbbb;
  background: #f0f0f0;
  color: #444444;
  padding: 1em;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 450px;
  font-size: 1.1em;
  line-height: 1.5em;
}
/* line 38, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-content h1, .vex.vex-theme-os .vex-content h2, .vex.vex-theme-os .vex-content h3, .vex.vex-theme-os .vex-content h4, .vex.vex-theme-os .vex-content h5, .vex.vex-theme-os .vex-content h6, .vex.vex-theme-os .vex-content p, .vex.vex-theme-os .vex-content ul, .vex.vex-theme-os .vex-content li {
  color: inherit;
}
/* line 41, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-close {
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
  -ms-border-radius: 0 5px 0 0;
  -o-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
/* line 48, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-close:before {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  content: "\00D7";
  font-size: 26px;
  font-weight: normal;
  line-height: 31px;
  height: 30px;
  width: 30px;
  text-align: center;
  top: 3px;
  right: 3px;
  color: #bbbbbb;
  background: transparent;
}
/* line 63, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-close:hover:before, .vex.vex-theme-os .vex-close:active:before {
  color: #777777;
  background: #e0e0e0;
}
/* line 69, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-form .vex-dialog-message {
  margin-bottom: 0.5em;
}
/* line 72, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-form .vex-dialog-input {
  margin-bottom: 1em;
}
/* line 75, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-form .vex-dialog-input textarea, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="date"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime-local"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="email"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="month"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="number"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="password"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="search"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="tel"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="text"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="time"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="url"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="week"] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: white;
  width: 100%;
  padding: 0.25em 0.67em;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  min-height: 2.5em;
  margin: 0 0 0.25em;
}
/* line 87, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
  -webkit-box-shadow: inset 0 0 0 1px #3288e6;
  -moz-box-shadow: inset 0 0 0 1px #3288e6;
  box-shadow: inset 0 0 0 1px #3288e6;
  outline: none;
}
/* line 91, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-form .vex-dialog-buttons {
  *zoom: 1;
}
/* line 38, ../../../../../.rvm/gems/ruby-1.9.3-p194/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/utilities/general/_clearfix.scss */
.vex.vex-theme-os .vex-dialog-form .vex-dialog-buttons:after {
  content: "";
  display: table;
  clear: both;
}
/* line 94, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-button {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 0;
  float: right;
  margin: 0 0 0 0.5em;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.75em 2em;
}
/* line 106, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-button.vex-last {
  margin-left: 0;
}
/* line 109, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-button:focus {
  animation: vex-pulse 1.1s infinite;
  -webkit-animation: vex-pulse 1.1s infinite;
  -moz-animation: vex-pulse 1.1s infinite;
  -ms-animation: vex-pulse 1.1s infinite;
  -o-animation: vex-pulse 1.1s infinite;
  -webkit-backface-visibility: hidden;
  outline: none;
}
@media (max-width: 568px) {
  /* line 109, ../sass/vex-theme-os.sass */
  .vex.vex-theme-os .vex-dialog-button:focus {
    animation: none;
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    -o-animation: none;
    -webkit-backface-visibility: hidden;
  }
}
/* line 118, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-button.vex-dialog-button-primary {
  background: #3288e6;
  color: white;
}
/* line 122, ../sass/vex-theme-os.sass */
.vex.vex-theme-os .vex-dialog-button.vex-dialog-button-secondary {
  background: #e0e0e0;
  color: #777777;
}

/* line 126, ../sass/vex-theme-os.sass */
.vex-loading-spinner.vex-theme-os {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0.5em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0.5em rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0.5em rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  width: 0;
  height: 0;
  border: 1.2em solid #bbbbbb;
  border-top-color: #f0f0f0;
  border-bottom-color: #f0f0f0;
}
