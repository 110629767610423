@font-face {
  font-family: 'weddingdayicons';
  src:  url('/fonts/weddingdayicons.eot?u13bui');
  src:  url('/fonts/weddingdayicons.eot?u13bui#iefix') format('embedded-opentype'),
    url('/fonts/weddingdayicons.ttf?u13bui') format('truetype'),
    url('/fonts/weddingdayicons.woff?u13bui') format('woff'),
    url('/fonts/weddingdayicons.svg?u13bui#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'weddingdayicons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-payment_set:before {
  content: "\e901";
}
.icon-payment_ticket:before {
  content: "\e902";
}
.icon-button_mails:before {
  content: "\e900";
}
.icon-list_guests:before {
  content: "\e904";
}
.icon-design:before {
  content: "\e905";
}
.icon-invitation_edit:before {
  content: "\e906";
}
.icon-response:before {
  content: "\e907";
}
.icon-reply:before {
  content: "\e908";
}
.icon-credit:before {
  content: "\e909";
}
.icon-btn_clipboard:before {
  content: "\e90a";
}
.icon-btn_download:before {
  content: "\e90b";
}
.icon-btn_new:before {
  content: "\e90c";
}
.icon-btn_premium:before {
  content: "\e90d";
}
.icon-navi_close:before {
  content: "\e90e";
}
.icon-navi_hamburger:before {
  content: "\e90f";
}
.icon-navi_order:before {
  content: "\e910";
}
.icon-tab_account:before {
  content: "\e911";
}
.icon-tab_invitation:before {
  content: "\e912";
}
.icon-tab_switch:before {
  content: "\e913";
}
.icon-home:before {
  content: "\e900";
}
